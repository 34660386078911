(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/youtube-video/assets/javascripts/init.js') >= 0) return;  svs.modules.push('/components/components/youtube-video/assets/javascripts/init.js');

'use strict';

const logger = svs.core.log.getLogger('component:youtube-video_init');
const {
  trackEvent
} = svs.components.analytics;
const YOUTUBE_URL = 'https://www.youtube-nocookie.com';

svs.components.youtube_video.init = function (options) {
  if (!options.videoId) {
    logger.warn('Missing videoId');
    return;
  }
  if (options && options.selector && options.src) {
    const videoHtml = svs.components.youtube_video.templates.youtube_video(options);
    $(options.selector).append(videoHtml);
  } else {
    logger.warn('Error in options object', options);
  }
};
const iframes = document.querySelectorAll('.js-youtube-video-iframe');
iframes.forEach(iframe => {
  iframe.contentWindow.postMessage('{"event":"listening","channel":"widget"}', '*');
});
window.addEventListener('message', evt => {
  if (evt.origin === YOUTUBE_URL) {
    let data;
    try {
      data = JSON.parse(evt.data);
    } catch (e) {
      logger.warn('Failed to parse message data:', e);
      return;
    }

    if (data && data.info && typeof data.info.playerState === 'number') {
      if (data.info.playerState === 1) {
        var _data$info$videoData, _data$info$videoData2;
        trackEvent({
          action: 'Video start',
          category: 'Youtube video',
          opt_label: "".concat((_data$info$videoData = data.info.videoData) === null || _data$info$videoData === void 0 ? void 0 : _data$info$videoData.video_id, " - ").concat((_data$info$videoData2 = data.info.videoData) === null || _data$info$videoData2 === void 0 ? void 0 : _data$info$videoData2.title),
          opt_value: 1
        });
      }
    }
  }
});

 })(window);