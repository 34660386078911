(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/youtube-video/views/youtube-video.js') >= 0) return;  svs.modules.push('/components/components/youtube-video/views/youtube-video.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_cmps=_svs.components=_svs.components||{};
_cmps.youtube_video=_cmps.youtube_video||{};
_cmps.youtube_video.templates=_cmps.youtube_video.templates||{};
svs.components.youtube_video.templates.youtube_video = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " youtube-video-container-fit";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "style=\"padding-bottom:"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"heightPercent") || (depth0 != null ? lookupProperty(depth0,"heightPercent") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"heightPercent","hash":{},"data":data,"loc":{"start":{"line":2,"column":47},"end":{"line":2,"column":64}}}) : helper)))
    + "%\" ";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return alias4(((helper = (helper = lookupProperty(helpers,"videoId") || (depth0 != null ? lookupProperty(depth0,"videoId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"videoId","hash":{},"data":data,"loc":{"start":{"line":5,"column":50},"end":{"line":5,"column":61}}}) : helper)))
    + "-"
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":5,"column":62},"end":{"line":5,"column":71}}}) : helper)));
},"7":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"videoId") || (depth0 != null ? lookupProperty(depth0,"videoId") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"videoId","hash":{},"data":data,"loc":{"start":{"line":5,"column":79},"end":{"line":5,"column":90}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"js-youtube-video-container youtube-video-container "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"fitWrapper") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":63},"end":{"line":1,"column":116}}})) != null ? stack1 : "")
    + "\"\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"heightPercent") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":74}}})) != null ? stack1 : "")
    + " data-video-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"videoId") || (depth0 != null ? lookupProperty(depth0,"videoId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"videoId","hash":{},"data":data,"loc":{"start":{"line":2,"column":90},"end":{"line":2,"column":101}}}) : helper)))
    + "\" itemprop=\"video\"\n    itemscope itemtype=\"http://schema.org/VideoObject\" data-json-ld='"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"jsonLDTags") || (depth0 != null ? lookupProperty(depth0,"jsonLDTags") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"jsonLDTags","hash":{},"data":data,"loc":{"start":{"line":3,"column":69},"end":{"line":3,"column":85}}}) : helper))) != null ? stack1 : "")
    + "'>\n    <div tabindex=\"0\" class=\"youtube-video-player js-youtube-video-player\">\n        <iframe data-tracking-label=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"title") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":5,"column":37},"end":{"line":5,"column":97}}})) != null ? stack1 : "")
    + "\"\n        loading=\"lazy\" class=\"js-youtube-video-"
    + alias4(((helper = (helper = lookupProperty(helpers,"videoId") || (depth0 != null ? lookupProperty(depth0,"videoId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"videoId","hash":{},"data":data,"loc":{"start":{"line":6,"column":47},"end":{"line":6,"column":58}}}) : helper)))
    + " js-youtube-video-iframe youtube-video-iframe\" id=\"js-youtube-video-"
    + alias4(((helper = (helper = lookupProperty(helpers,"videoId") || (depth0 != null ? lookupProperty(depth0,"videoId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"videoId","hash":{},"data":data,"loc":{"start":{"line":6,"column":126},"end":{"line":6,"column":137}}}) : helper)))
    + "\"\n        data-videoid=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"videoId") || (depth0 != null ? lookupProperty(depth0,"videoId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"videoId","hash":{},"data":data,"loc":{"start":{"line":7,"column":22},"end":{"line":7,"column":33}}}) : helper)))
    + "\" src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"src") || (depth0 != null ? lookupProperty(depth0,"src") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"src","hash":{},"data":data,"loc":{"start":{"line":7,"column":40},"end":{"line":7,"column":47}}}) : helper)))
    + "&autoplay=0&enablejsapi=1\" allowfullscreen=\"1\"\n        allow=\"accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; clipboard-read; clipboard-write\"></iframe>\n    </div>\n</div>\n";
},"useData":true});
Handlebars.partials['components-components-youtube_video-youtube_video'] = svs.components.youtube_video.templates.youtube_video;
})(svs, Handlebars);


 })(window);